import React, { useEffect, useState } from "react";
import Home from "../Containers/Home";
import Login from "../Components/Login";
import ClassRoom from "../Containers/ClassRoom";
import ElevesHome from "../Containers/ElevesHome";
import StaticHome from "../Containers/StaticHome";
import NotFound from "../Components/NotFound";
import ClassRoomByEleve from "../Containers/ClassRoomByEleve";
import PersonelHome from "../Containers/PersonelHome";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PresenceHome from "../Containers/PresenceHome";

export default function ApiRoutes(){
    return (
        <div>
          <Router>
              <Routes>
                <Route path="/" exact element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/eleves" element={<ElevesHome />} />
                <Route path="/presences" element={<PresenceHome />} />
                <Route path="/personnels" element={<PersonelHome />} />
                <Route path="/classroom" element={<ClassRoom />} />
                <Route path="/classbyeleves" element={<ClassRoomByEleve />} />
                <Route path="/statistiques" element={<StaticHome />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
          </Router>
        </div>
      );
}
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const CreateModalPatrimoine = ({ isOpen, onClose, onCreate, apiUrl, initialFormData }) => {
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        setFormData(initialFormData);
    }, [initialFormData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onCreate(response.data);
            onClose();
        } catch (error) {
            console.error('Erreur lors de la création de l\'élément:', error);
        }
    };

    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter Un Patrimoine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formNom">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type="text"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formQuantity">
                        <Form.Label>Quantité</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group> <br />
                    <Form.Group controlId="formValeur">
                        <Form.Label>Valeur</Form.Label>
                        <Form.Control
                            type="number"
                            name="valeur"
                            value={formData.valeur}
                            onChange={handleChange}
                            required
                            //readOnly
                        />
                    </Form.Group>
                    <br />
                    <Button variant="primary" type="submit">
                        Ajouter
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateModalPatrimoine;

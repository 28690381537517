import React, { useEffect, useState } from "react";
import './Style/App.css';
import ApiRoutes from "./Routes/ApiRoutes";
import { DataProvider} from './Context/DataProvider'; // Import the DataProvider
//import {AuthProvider} from './Context/AuthContext'
//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


export default function App() {
  return (
   
      <DataProvider>
        <div>
          <ApiRoutes />
        </div>
      </DataProvider>
    
  );
}

import React from "react";
import moment from "moment";
import "moment/locale/fr";
import { Table, Button } from "react-bootstrap";
import { del } from "../Services/Api.js";

export default function ListsEleves({ data }) {
  const formatDateToHumanReadable = (date) => {
    return moment(date).locale("fr").fromNow();
  };

  const handleDelete = (id) => {
    del(`/presms/eleves/${id}`)
      .then(() => {
        // You can update the parent component's data and re-render it
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  };

  return (
    <div>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>Date de Naissance</th>
              <th>Adresse</th>
              <th>Telephone</th>
              <th>Groupe Sanguin</th>
              <th>Email</th>
              <th>Ecole Precedente</th>
              <th>Photo</th>
              <th>Classes</th>
              <th>Updated</th>
              <th>Created</th>
              <th>Voir</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td>{item.date_of_birth}</td>
                  <td>{item.address}</td>
                  <td>{item.telephone}</td>
                  <td>{item.blood_group}</td>
                  <td>{item.email}</td>
                  <td>{item.previous_school_name}</td>
                  <td>
                    <img
                      src={item.photo}
                      alt="Item Photo"
                      width="50"
                      height="50"
                    />
                  </td>
                  <td>{item.class_assigned}</td>
                  <td>{formatDateToHumanReadable(item.updated)}</td>
                  <td>{formatDateToHumanReadable(item.created)}</td>
                  <td>
                    <Button variant="info" size="sm" className="mr-2">
                      View
                    </Button>
                  </td>
                  <td>
                    <Button variant="success" size="sm" className="mr-2">
                      Payment
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="15">No data available.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

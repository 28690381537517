import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ListStatistiques from '../Components/ListStatistiques';
import CreateRecordModal from '../Components/Modal/CreateRecordModal';
import Header2 from '../Components/PageLayout/Header2';
import { get } from '../Services/Api';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Footer from '../Components/PageLayout/Footer';
import Banner from '../Components/PageLayout/Banner';

export default function StaticHome() {

    const [elevepayement, setElevePayement] = useState([]);
    const [dataEleve, setDataEleve] = useState([]);
    const [profpayement, setProfPayement] = useState([]);
    const [staffpayement, setStaffsPayement]= useState([]);
    const [otherpayement, setOtherPayement] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingEleve, setIsLoadingEleve] = useState(false);
   
    const fetchDataAll = async () => {
        setIsLoadingData(true);
        const timestamp = Date.now();
        try {
          // Fetch the first resource
          const response1 = await get(`/presms/elevepayement/calculate_payment_global/?timestamp=${timestamp}`);
          
          console.log("Total Payement Amount In StaticHome:", response1.total_payment);
                //setElevePayement(response.total_payment);
                setElevePayement(response1.total_payment);
      
          // Fetch the second resource
          const response2 = await get(`/presms/professeurpayements/calculate_payment_global/?timestamp=${timestamp}`);
          console.log('Total Payement Amount In StaticHome:', response2);
          setProfPayement(response2.total_salary);

          // Fetch the second resource
          const response3 = await get(`/presms/stafftspayement/calculate_payment_global/?timestamp=${timestamp}`);
          console.log('Total Payement Amount In StaticHome:', response3);
          setStaffsPayement(response3.payement_amount);
          
          // Fetch the second resource
          const response4 = await get(`/presms/otherscharges/calculate_payment_global/?timestamp=${timestamp}`);
          console.log('Total Payement Amount In StaticHome:', response4);
          setOtherPayement(response4.payement_amount);
          // fetch the fifth resource
          const response5 = await get(`/presms/eleves/?timestamp=${timestamp}`);
          console.log('List des eleves In StaticHome:', response5);
          setDataEleve(response5)
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoadingData(false);
        }
      };
      
    
    const handleRefresh = () => {
        fetchDataAll();
    };

    useEffect(() => {
        fetchDataAll();
    }, []); // Fetch data when the component mounts

    return (
        <>
            <Header2/>
            <div style={{ paddingTop: '80px' }}>
                
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h3
                                    style={{
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
                                        padding: '10px',
                                        color: '#006400',
                                    }}
                                >
                                    Statistiques
                                </h3>
                                <Button variant="success" onClick={handleRefresh}>
                                    Actualiser
                                </Button>
                            </div>
                            <ListStatistiques 
                               otherpayement={otherpayement}
                               elevepayement={elevepayement} 
                               profpayement = {profpayement}
                               staffpayement = {staffpayement}
                               dataEleve = {dataEleve}
                               />
                        </Col>
                    </Row>
                </Container>
            </div>
            <br />
            <Footer />
        </>
    );
}

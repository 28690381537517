import React from "react";
import ListPresences from "../Components/ListPresences";
import Header2 from "../Components/PageLayout/Header2";
import Footer from "../Components/PageLayout/Footer";
import Banner from "../Components/PageLayout/Banner";
import { Col, Container, Row } from "react-bootstrap";

export default function PresenceHome(){
    return(
        <>
        <Header2 />
         <div style={{paddingTop:'70px'}}>
            <Container>
                <Banner />
                <Row>
                    <Col>
                    <ListPresences/>
                    </Col>
                </Row>
            </Container>
        <Footer />
        </div>
        </>
       
    )
}
// Footer.js
import React,{useContext} from 'react';
import { DataContext } from '../../Context/DataProvider';

export default function Footer() {
    const {school} = useContext(DataContext)
    return (
        <div className="mt-auto">
            <footer className="bg-dark text-white text-center py-3">
                <p>&copy; {new Date().getFullYear()}.
                {school && school.length!==0 && school[0].name}.Developed by
                 <a href="https://linkedin.com/in/moussadiallo14" 
                target="_blank" rel="noopener noreferrer"> Moussa DIALLO</a>
                . All rights reserved.</p>
            </footer>
        </div>   
    );
}

import React from 'react';
import { Navbar, Container, Nav, NavDropdown, Form, Button, Image } from 'react-bootstrap';
import { FaHome, FaLink, FaSearch, FaSignOutAlt, FaUser, FaReadme, FaPortrait } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,faHome, faPerson, faSchool,faPersonChalkboard, faSignOut, faChartBar, faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Header2({ onLogout }) {
  const username = localStorage.getItem('enteredUsername');
  
  const handleLogout = (e) => {
    e.preventDefault();
    // Clear localStorage on logout
    localStorage.removeItem('enteredUsername');
    localStorage.removeItem('authToken'); // Remove the token key as well
    window.location.href = '/';
    // Implement your logout logic here
    console.log('Logout clicked');
  };
  
  return (
    <Navbar expand="lg" bg="light" className="align-items-center fixed-top" 
      style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
       }}>
      <Container>
        <Navbar.Brand style={{ marginLeft: '10px' }}>
          <Link to="/home">
            <Image
              src="/images/logo.jpg"
              alt="My Logo"
              style={{ width: '70px', height: '40px', borderRadius: '10%', boxShadow: '2px 2px 5px #888888' }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0">
            <Link to="/home" className='nav-link'>
              <FontAwesomeIcon icon={faHome} />  Home
            </Link>
            <Link to="/classroom" className="nav-link">
            <FontAwesomeIcon icon={faSchool} /> Classes
            </Link>
            <Link to="/personnels" className="nav-link">
            <FontAwesomeIcon icon={faPerson} />  Personnels
            </Link>
            <Link to="/statistiques" className="nav-link">
            <FontAwesomeIcon icon={faChartColumn} /> Statistiques
            </Link>
            {/*<Link to="/presences" className="nav-link">
              <FontAwesomeIcon icon={faEye} /> Presences
            </Link>*/}
          </Nav>
          <Nav className="ml-auto">
            <Link to="/feedback" className="nav-link">
              <FontAwesomeIcon icon={faPersonChalkboard} /> Bonjour, {username}
            </Link>
            <Nav.Link onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOut} /> Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

import React,{useState, useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { del } from "../../Services/Api";

export default function DeleteConfirmationModal({
  isOpen,
  onClose,
  data,
  apiUrl,
  initialFormData // Add apiUrl as a prop
}) {
  const [formData, setFormData] = useState(initialFormData);
  console.log("deleting urlAPi :", apiUrl)
  console.log("deleting initialFormData :", initialFormData)

  // Update formData when the component mounts
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      ...initialFormData, // Spread all properties from initialFormData
    }));
  }, [initialFormData]); // Trigger the effect when any property in initialFormData changes
  const onConfirmDelete = async () => {
    console.log("Complete URL for deletion:", apiUrl); // apiUrl is already correctly formed
    try {
      await del(apiUrl); // Assuming del function correctly handles deletion
      console.log("Successfully deleted  with ID:", data.id);
      onClose(); // Close modal after successful deletion
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };
  
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:'red'}}>Supprimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <div>
            <p>Êtes-vous sûr(e) de vouloir supprimer ce element id ?</p>
            <p>ID: {data.id}</p>
            <p>Classe: {data.class_name}</p> {/* Display additional details directly from data */}
            {/* You can display more details similarly */}
          </div>
        ) : (
          <p>No data available for this classroom.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Non
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onConfirmDelete(data.id);
            onClose();
          }}
        >
          Oui, Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import Header2 from '../Components/PageLayout/Header2';
import Footer from '../Components/PageLayout/Footer';
import Banner from '../Components/PageLayout/Banner';
import SearchBar from '../Components/PageLayout/SearchBar';
import ListsEleves from '../Components/ListsEleves';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CreateRecordModal from '../Components/Modal/CreateRecordModal';
import { get } from '../Services/Api'; // Import the 'get' and others function from your api.js

export default function ElevesHome() {
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleCreate = (newRecord) => {
        // Update the data state with the new record
        setData([...data, newRecord]);
    };

    const fetchData = () => {
        setIsLoading(true);
        get('/presms/eleves/')
            .then((response) => {
                setData(response);
                console.log(data)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []); // Fetch data when the component mounts

    return (
        <>
        <Header2 />
    
        <div className="container mt-3" style={{paddingTop:'70px'}}>
            <SearchBar />
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h1>Listes Generales des élèves</h1>
                        <Button variant="primary" onClick={openModal}>
                            Ajouter Un Élève
                        </Button>
                    </div>
                    <CreateRecordModal
                        isOpen={modalIsOpen}
                        onClose={closeModal}
                        onCreate={handleCreate}
                        apiUrl="/presms/eleves/"
                        initialFormData={{
                            first_name: '',
                            last_name: '',
                            date_of_birth: '',
                            address: '',
                            telephone: '',
                            blood_group: '',
                            email: '',
                            previous_school_name: '',
                            photo: '',
                            academic_year: '',
                            class_assigned: '',
                        }}
                    />
                    <ListsEleves data={data} isLoading={isLoading} />
                </div>
            </div>
        </div> 
        <Footer />
    </>
    );
}

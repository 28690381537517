import React, { useEffect, useState, useContext } from "react";
import { useDataContext } from "../../Context/DataProvider";
import { Modal, Button, Spinner, Table, Container, Col, Row, Form } from "react-bootstrap";
import SearchBar from "../PageLayout/SearchBar";
import { DataContext } from "../../Context/DataProvider";
import CreateModalProf from "./CreateModalProf";
import PayementRecord from "./PayementRecord";
import moment from "moment";
import "moment/locale/fr";
import CurrencyComponent from "../../Utils/CurrencyComponent";
import CreateModalEmp from "./CreateModalEmp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

export default function ViewRecordEmp({ isOpen, onClose, data }) {
  const [loading, setLoading] = useState(false);
  const { academicYears, categories, classRoom } = useContext(DataContext);
  const [elevepayement, setElevePayement] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalPayementAmount, setTotalPayementAmount] = useState(0);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");

  const handleAcademicYearChange = (e) => {
    setSelectedAcademicYear(e.target.value);
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    alignItems: 'center',
  };

  const getYearNameById = (id) => {
    const year = academicYears.find((year) => year.id === id);
    return year ? year.year_name : "N/A";
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getCategoryNameById = (id) => {
    const category = categories.find((category) => category.id === id);
    return category ? category.category_name : "N/A";
  };

  const handleSearch = () => {
    console.log("Value filteredData in Parent Classrrom cpm");
  };

  const fetchAndSetElevePayement = () => {
    setLoading(true);
    const timestamp = Date.now();

    fetch(`https://hmdgn.com/presms/api/staffpayement/${data.id}/?timestamp=${timestamp}`)
      .then((response) => response.json())
      .then((fetchedData) => {
        setElevePayement(fetchedData);
        const totalPayementAmount = fetchedData.reduce(
          (accumulator, eleve) => accumulator + parseFloat(eleve.payement_amount),
          0
        );
        setTotalPayementAmount(totalPayementAmount);
      })
      .catch((error) => console.error("Error fetching eleves data:", error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreate = (newRecord) => {
    setElevePayement((prevElevePayement) => {
      const updatedElevePayement = [...prevElevePayement, newRecord];
      setTotalPayementAmount((prevTotalAmount) => {
        const updatedTotalAmount = prevTotalAmount + parseFloat(newRecord.payement_amount);
        return updatedTotalAmount;
      });
      return updatedElevePayement;
    });
  };

  useEffect(() => {
    if (isOpen || data) {
      fetchAndSetElevePayement();
    }
  }, [isOpen, data]);

  useEffect(() => {
    console.log("staff data by Class:", data);
    console.log("Class Data from ViewModal by eleve:", data);
  }, [data]);

  return (
    <div>
      {isOpen && !modalIsOpen && (
        <Modal show={isOpen} onHide={onClose} className="custom-modal-width" size="xl">
          <Modal.Header closeButton className="custom-modal-header">
            <Modal.Title>Matricule Du Professeur: EHMD{data ? data.id : 'N/A'}</Modal.Title>
          </Modal.Header>
          <div style={{ maxHeight: "600px", overflowY: "auto", padding: "10px" }}>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={6} md={8}>
                    {data ? (
                      <div>
                        <p><strong>First Name:</strong> {data.first_name}</p>
                        <p><strong>Last Name:</strong> {data.last_name}</p>
                        <p><strong>Telephone:</strong> {data.telephone}</p>
                        <p><strong>Address:</strong> {data.address}</p>
                        <p><strong>Responsibilities: </strong> {data.titre ? data.titre : 'n/a'}</p>
                      </div>
                    ) : (
                      'N/A'
                    )}
                  </Col>
                  <Col xs={6} md={4}>
                    <div className="header-image" style={{ position: 'relative' }}>
                      {data.photo ? (
                        <img
                          src={data.photo}
                          style={{
                            width: '100px',
                            height: '100px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            borderRadius: '25px',
                          }}
                          alt="Profile"
                        />
                      ) : (
                        <img src="/images/avatar.jpeg"
                          style={{
                            width: '100px',
                            height: '100px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            borderRadius: '25px',
                          }}
                          alt="photo"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row style={{ backgroundColor: "light" }}>
                  <Col xs={6} md={4}>
                    <p style={titleStyle}>Montant Total</p>
                    <CurrencyComponent amount={totalPayementAmount} currency="GNF" />
                  </Col>
                </Row>
              </Container>
              <hr />
              <SearchBar onSearch={handleSearch} data={data} />
              <hr />
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <h4
                    style={{
                      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      padding: "10px",
                      color: "#006400",
                    }}
                  >
                    Details Payement
                  </h4>
                  <Form.Group as={Row} className="mb-0 ml-2">
                    <Form.Label column sm="auto" className="mr-2"></Form.Label>
                    <Col sm="auto">
                      <Form.Control
                        as="select"
                        size="sm"
                        style={{ color: "#006400" }}
                        value={selectedAcademicYear}
                        onChange={handleAcademicYearChange}
                      >
                        <option value="">Toutes les années académiques</option>
                        {academicYears.map((year) => (
                          <option key={year.id} value={year.id}>
                            {year.year_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </div>
                <Button variant="info" size="sm" onClick={openModal}>
                  <FontAwesomeIcon icon={faDollarSign} /> Ajouter
                </Button>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  </div>
                ) : elevepayement && elevepayement.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Mensualite</th>
                        <th>Nature</th>
                        <th>Montant</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Observation</th>
                        <th>Mod</th>
                        <th>Sup</th>
                      </tr>
                    </thead>
                    <tbody>
                      {elevepayement.map((eleve) => (
                        <tr key={eleve.id}>
                          <td>{eleve.id}</td>
                          <td>{eleve.payement_mensuel}</td>
                          <td>{eleve.payement_nature}</td>
                          <td><CurrencyComponent amount={eleve.payement_amount} currency="GNF" /></td>
                          <td>{eleve.payement_type}</td>
                          <td>{new Date(eleve.created).toLocaleDateString()}</td>
                          <td>{eleve.observation}</td>
                          <td>
                            <Button
                              variant="success"
                              size="sm"
                              className="mr-2"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              size="sm"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>Données non disponibles...!</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={onClose}>
                Fermer
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      )}
      <CreateModalEmp
        isOpen={modalIsOpen}
        onClose={closeModal}
        onCreate={(newRecord) => {
          handleCreate(newRecord);
        }}
        apiUrl="/presms/stafftspayement/"
        initialFormData={{
          payement_mensuel: '',
          payement_nature: '',
          observation: '',
          payement_type: '',
          payement_amount: '',
          academic_year: '',
          staffId: data ? data.id : '',
        }}
      />
      
    </div>
  );
}

import React, {useState, useContext} from "react";
import { DataContext } from "../Context/DataProvider.js";
import moment from "moment";
import "moment/locale/fr";
import { Table, Button,Pagination } from "react-bootstrap";
import { del } from "../Services/Api.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBar from "./PageLayout/SearchBar.js";
import CurrencyComponent from "../Utils/CurrencyComponent.js";
import { faEye, faDollarSign, faTrash, faEdit, faVcard } from '@fortawesome/free-solid-svg-icons';
import ViewRecordStaffs from "./Modal/ViewRecordStaffs.js";

export default function ListOthersCharges({ data,onCreate}) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  //const apiUrl = "/presms/classroom"; // Set the API URL as needed
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [classroomToDelete, setClassroomToDelete] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const { academicYears, categories } = useContext(DataContext); // Use your DataContext
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;


  const formatDateToHumanReadable = (date) => {
    return moment(date).locale("fr").fromNow();
  };

  const handleView = (id) => {
    setSelectedItemId(id);
    console.log("This is the prof id", id); // Use 'id' directly
    setIsViewModalOpen(true);
    // window.location.href = '/classbyeleves';
  };
  const handleSearch = (filteredData) => {
    setFilteredData(filteredData);
    console.log("Value filteredData in Parent ListOtheChares cpm",filteredData);
  };
  const handleCreate = (updatedRecord) => {
    // Call the provided onCreate function to update the data in the parent component
    onCreate(updatedRecord);
  };
  const handleCloseView = () => {
    setSelectedItemId(null);
    setIsViewModalOpen(false);
    setIsUpdateModalOpen(false);
  };
  const handleDelete = (id) => {
    del(`/presms/eleves/${id}`)
      .then(() => {
        // You can update the parent component's data and re-render it
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = Array.isArray(filteredData) && filteredData.length > 0
    ? filteredData.slice(startIndex, endIndex)
    : data.slice(startIndex, endIndex);

  return (
    <div>
          <SearchBar
                    data={data}
                    onSearch={handleSearch}
                    filterFields={['id', 'payement_nature','created']}
                    placeholder="Rechercher par date or nature de payement"
                    buttonLabel="Rechercher"
            />
          <hr />
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Mois</th>
              <th>Nature</th>
              <th>observation</th>
              <th>payement Type</th>
              <th>Montant</th>
              <th>Date</th>
              <th>Charges</th>
              <th>Modif</th>
              <th>Sup</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(currentData) && currentData.length > 0 ? (
              currentData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.payement_mensuel}</td>
                  <td>{item.payement_nature}</td>
                  <td>{item.observation}</td>
                  <td>{item.payement_type}</td>
                  <td>{item.payement_amount}</td>
                  <td>{new Date(item.created).toLocaleDateString()}</td>
                  <td>{item.charge_name}</td>
                  <td>
                    <Button variant="success" size="sm" className="mr-2">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="15">Données non disponibles. Problème de connexion. Oops...😕</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination>
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / itemsPerPage)))}
            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          />
        </Pagination>
      </div>
    </div>
  );
}

import React, { useState, useContext } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Table, Button, Pagination } from "react-bootstrap";
import { del } from "../Services/Api.js";
import { DataContext } from "../Context/DataProvider.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBar from "./PageLayout/SearchBar.js";
import CurrencyComponent from "../Utils/CurrencyComponent.js";
import { faEye, faDollarSign, faTrash, faEdit, faVcard } from '@fortawesome/free-solid-svg-icons';
import ViewRecordProf from "./Modal/ViewRecordProf.js";
import DeleteConfirmationModal from "./Modal/DeleteConfirmationModal.js";
import UpdateRecordModal from "./Modal/UpdateRecordModal.js";

export default function ListProfesseurs({ data, onCreate }) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [classroomToDelete, setClassroomToDelete] = useState(null);
  const { academicYears, categories, classRoom } = useContext(DataContext);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const formatDateToHumanReadable = (date) => {
    return moment(date).locale("fr").fromNow();
  };

  const handleView = (id) => {
    setSelectedItemId(id);
    setIsViewModalOpen(true);
  };
  const handleCreate = (updatedRecord) => {
    // Call the provided onCreate function to update the data in the parent component
    onCreate(updatedRecord);
  };
  const handleCloseView = () => {
    setSelectedItemId(null);
    setIsViewModalOpen(false);
    setIsUpdateModalOpen(false);
  };
  const handleUpdate = (id) =>{
    setSelectedItemId(id);
    setIsUpdateModalOpen(true);
  }
  const handleSearch = (filteredData) => {
    setFilteredData(filteredData);
    setCurrentPage(1);
  };
  const onCloseDeleteModal = () => {
    setClassroomToDelete(null);
    setIsDeleteModalOpen(false);
  };
  
  const handleDelete = (id) => {
    const professeurId = data.find((item) => item.id === id);
    setClassroomToDelete(professeurId);
    setIsDeleteModalOpen(true);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = Array.isArray(filteredData) && filteredData.length > 0
    ? filteredData.slice(startIndex, endIndex)
    : data.slice(startIndex, endIndex);

  return (
    <div>
      <SearchBar
        data={data}
        onSearch={handleSearch}
        filterFields={['id', 'first_name', 'telephone']}
        placeholder="Rechercher par id, telephone or nom de l'employer "
        buttonLabel="Rechercher"
      />
      <hr />
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>Telephone</th>
              <th>Voir</th>
              <th>Modif</th>
              <th>Sup</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(currentData) && currentData.length > 0 ? (
              currentData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td>{item.telephone}</td>
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleView(item.id)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="success"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleUpdate(item.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">en cours de telechargement Oops...😕</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination>
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / itemsPerPage)))}
            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          />
        </Pagination>
        <ViewRecordProf
          isOpen={isViewModalOpen}
          onClose={handleCloseView}
          data={data.find((item) => item.id === selectedItemId)}
        />
        <UpdateRecordModal
          isOpen={isUpdateModalOpen}
          onClose={handleCloseView}
          onCreate={handleCreate}
          apiUrl={`/presms/professeurs/${data.find((item) => item.id === selectedItemId)?.id}/`}
          initialFormData={{
            first_name: data.find((item) => item.id === selectedItemId)?.first_name || '',
            last_name: data.find((item) => item.id === selectedItemId)?.last_name || '',
            date_of_birth: data.find((item) => item.id === selectedItemId)?.date_of_birth || '',
            telephone: data.find((item) => item.id === selectedItemId)?.telephone || '',
          }}
        />
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={onCloseDeleteModal}
          data={classroomToDelete} // Pass the entire classroom object
          apiUrl={`/presms/professeurs/${classroomToDelete?.id}/`} // This now directly uses classroomToDelete
        />
      </div>
    </div>
  );
}
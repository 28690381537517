import React, { useState, useEffect } from 'react';
import Header2 from '../Components/PageLayout/Header2';
import Footer from '../Components/PageLayout/Footer';
import Banner from '../Components/PageLayout/Banner';
import SearchBar from '../Components/PageLayout/SearchBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ListClassroom from '../Components/ListClassroom';
import CreateRecordModal from '../Components/Modal/CreateRecordModal';
import CurrencyComponent from '../Utils/CurrencyComponent';
import { get } from '../Services/Api'; // Import the 'get' function from your api.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons';
import CreateModalClass from '../Components/Modal/CreateModalClass';

export default function ClassRoom() {
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    
    //const handleCreate = (newRecord) => {
        // Update the data state with the new record
    //    setData([...data, newRecord]);
    //};
    const handleDataUpdate = (updatedRecord) => {
        // Update the data in the parent component
        setData((prevData) => {
          const index = prevData.findIndex((item) => item.id === updatedRecord.id);
          if (index !== -1) {
            const newData = [...prevData];
            newData[index] = updatedRecord;
            return newData;
          }
          return prevData;
        });
      };
    const handleSearch = (filteredData) => {
        setFilteredData(filteredData);
    };
    console.log("Value filteredData in Parent Classrrom cpm",filteredData);
    const fetchData = () => {
        setIsLoading(true);
        const timer = Date.now(); // You can use another method to generate a unique timer
        get(`/presms/classroom/?timer=${timer}`)
            .then((response) => {
                setData(response);
                console.log("Data from classRoom componenent",data)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error:', error);
            });
    };
    useEffect(() => {
        fetchData();
    }, []); // Fetch data when the component mount

    return (
            <>
                <Header2 />
                <div className="container mt-3" style={{paddingTop:'70px'}}>
                  <SearchBar
                    data={data}
                    onSearch={handleSearch}
                    filterFields={['id', 'class_name','category']}
                    placeholder="Rechercher par Matricule ou par nom"
                    buttonLabel="Rechercher"
                    />
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3 
                                      style={{ 
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', 
                                        padding: '10px',
                                        color: '#006400', 
                                        }}>
                                        Listes des Classes
                                    </h3>
                                    <Button variant="primary" onClick={openModal}>
                                        <FontAwesomeIcon icon={faAdd} /> Ajouter
                                    </Button>
                                </div>

                                <CreateModalClass
                                    isOpen={modalIsOpen}
                                    onClose={closeModal}
                                    onCreate={(newRecord) => setData([...data, newRecord])}
                                    updateData={(newRecord) =>  setData([...data, newRecord])}
                                    apiUrl="/presms/classroom/"
                                    initialFormData={{
                                        class_name: '',
                                        total_amount:'',
                                        category: '',
                                        academic_year: '',
                                    }}
                                />
                                {/* This is the Child ListClassrom to display data */}
                                <ListClassroom data={data} filteredData={filteredData} isLoading={isLoading} onCreate={handleDataUpdate}  />
                            </Col>
                        </Row>
                    </Container>

                </div>

                <Footer />
            </>
    );
}

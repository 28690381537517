import React, { createContext, useEffect, useState, useContext } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [classRoom, setClassRoom] = useState([]);
  const [school, setSchool] = useState([]);
  const [eleveData, setEleveData] = useState(/* initial eleve data */);
  const [elevePaymentData, setElevePaymentData] = useState(/* initial eleve payment data */);
  const [classroomData, setClassroomData] = useState(/* initial classroom data */);
  const [userData, setUserData] = useState(null);

  const fetchData = async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(`Error fetching data from ${url}: ${error}`);
    }
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("authToken"); // Replace with your actual key
      const response = await fetch("https://hmdgn.com/presms/users/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const userData = await response.json();
      setUserData(userData);
      console.log("users information Profile", userData)
      console.log("users information Profile Token ", token)
    } catch (error) {
      console.error(`Error fetching user data: ${error}`);
    }
  };

  useEffect(() => {
    // Check if data is already available in cache
    if (categories.length === 0) {
      fetchData("https://hmdgn.com/presms/category/", setCategories);
    }

    // Check if data is already available in cache
    if (academicYears.length === 0) {
      fetchData("https://hmdgn.com/presms/academicyear/", setAcademicYears);
    }
    // Check if data is already available in cache
    if (school.length === 0) {
      fetchData("https://hmdgn.com/presms/school/", setSchool);
    }

    // Check if data is already available in cache
    if (classRoom.length === 0) {
      fetchData("https://hmdgn.com/presms/classroom/", setClassRoom);
    }

    // Fetch user data
    if (!userData) {
      fetchUserData();
    }
  }, [userData]); // Trigger fetchUserData when userData changes

  return (
    <DataContext.Provider
      value={{
        categories,
        academicYears,
        classRoom,
        eleveData,
        setEleveData,
        elevePaymentData,
        setElevePaymentData,
        classroomData,
        setClassroomData,
        school,
        userData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  return useContext(DataContext);
};

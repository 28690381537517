import React, { useState, useContext } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Table, Button } from "react-bootstrap";
import ViewRecordModal from "./Modal/ViewRecordModal.js";
import UpdateRecordModal from "./Modal/UpdateRecordModal.js";
import DeleteConfirmationModal from "./Modal/DeleteConfirmationModal.js";
import { Link } from 'react-router-dom';
import ClassRoomByEleve from "../Containers/ClassRoomByEleve.js";
import { DataContext } from "../Context/DataProvider.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyComponent from "../Utils/CurrencyComponent.js";
import { faEye, faDollarSign, faTrash, faEdit, faVcard } from '@fortawesome/free-solid-svg-icons';
//import { del } from "../Services/Api.js"; // Import the del function from your api.js

export default function ListClassroom({ data,filteredData, onCreate}) {

  const [selectedItemId, setSelectedItemId] = useState(null);
  //const apiUrl = "/presms/classroom"; // Set the API URL as needed
  //const [data, setData] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [classroomToDelete, setClassroomToDelete] = useState(null);
  const { academicYears, categories, userData } = useContext(DataContext); // Use your DataContext
  const getYearNameById = (id) => {
    // Find the academic year by ID and return its year_name
    const year = academicYears.find((year) => year.id === id);
    return year ? year.year_name : "N/A";
  };

  const getCategoryNameById = (id) => {
    // Find the category by ID and return its category_name
    const category = categories.find((category) => category.id === id);
    return category ? category.category_name : "N/A";
  };
  const handleView = (id) => {
    setSelectedItemId(id);
    setIsViewModalOpen(true);
    //window.location.href = '/classbyeleves';
  };
  if (userData && userData.length > 0) {
    console.log("This is information user profile from ListClassroom", userData[0]);
  } else {
    console.log("userData is not loaded yet");
  }
  const handleCreate = (updatedRecord) => {
    // Call the provided onCreate function to update the data in the parent component
    onCreate(updatedRecord);
  };
  const handleUpdate = (id) =>{
    setSelectedItemId(id);
    setIsUpdateModalOpen(true);
  }
  const handleCloseView = () => {
    setSelectedItemId(null);
    setIsViewModalOpen(false);
    setIsUpdateModalOpen(false);
  };
  const onCloseDeleteModal = () => {
    setClassroomToDelete(null);
    setIsDeleteModalOpen(false);
  };
  
  const handleDelete = (id) => {
    const classroom = data.find((item) => item.id === id);
    setClassroomToDelete(classroom);
    setIsDeleteModalOpen(true);
  };
  const handleConfirmDelete = (id) => {
    // Call your delete API or perform the deletion logic here
    // Once the classroom is deleted, you can update your data
    // and re-render the component if needed.
  };
  return (
    <div>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Classe</th>
              <th>Categorie</th>
              <th>Scolarite</th>
              <th>Voir</th>
              <th>Modif</th>
              {userData && userData[0].is_staff && (
                <th>Sup</th>
              )}
              
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.class_name}</td>
                  <td>{getCategoryNameById(item.category)}</td> {/* Display category name by ID */}
                  <td><CurrencyComponent amount={item.total_amount ? item.total_amount : ''} currency="GNF" /></td> {/* Display the scholarte par class */}
                  
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleView(item.id)}
                      >
                      <FontAwesomeIcon icon={faEye} />
                    </Button> 
                  </td>
                  <td>
                    <Button 
                      variant="success" 
                      size="sm" 
                      className="mr-2"
                      onClick={() => handleUpdate(item.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                  {userData && userData[0].is_staff && (
                    <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                  )}
                  
                </tr>
              ))
            ) : Array.isArray(data) && data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.class_name}</td>
                  <td>{getCategoryNameById(item.category)}</td>
                  <td><CurrencyComponent amount={item.total_amount ? item.total_amount : ''} currency="GNF" /></td>
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleView(item.id)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="success"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleUpdate(item.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                  {userData && userData[0].is_staff && (
                    <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">Données non disponibles. Problème de connexion. Oops 😕</td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Modals for viewing, updating, and deleting records */}
        <ViewRecordModal
          isOpen={isViewModalOpen}
          onClose={handleCloseView}
          data={data.find((item) => item.id === selectedItemId)}
        />
        <UpdateRecordModal
          isOpen={isUpdateModalOpen}
          onClose={handleCloseView}
          onCreate={handleCreate}
          apiUrl={`/presms/classroom/${data.find((item) => item.id === selectedItemId)?.id}/`}
          initialFormData={{
            class_name: data.find((item) => item.id === selectedItemId)?.class_name || '',
            total_amount: data.find((item) => item.id === selectedItemId)?.total_amount || '',
            category: data.find((item) => item.id === selectedItemId)?.category || '',
            academic_year: data.find((item) => item.id === selectedItemId)?.academic_year || '',
          }}
        />
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={onCloseDeleteModal}
          data={classroomToDelete} // Pass the entire classroom object
          apiUrl={`/presms/classroom/${classroomToDelete?.id}/`} // This now directly uses classroomToDelete
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ListPatrimoine from '../Components/ListPatrimoine';
import CreateModalPatrimoine from '../Components/Modal/CreateModalPatrimoine';
import Header2 from '../Components/PageLayout/Header2';
import { DataContext } from '../Context/DataProvider';
import { get } from '../Services/Api';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Footer from '../Components/PageLayout/Footer';
import Banner from '../Components/PageLayout/Banner';

export default function Home() {
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userData } = useContext(DataContext);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleCreate = (newRecord) => {
        setData([...data, newRecord]);
    };

    const fetchData = () => {
        get('/presms/patrimoine/')
            .then((response) => {
                setData(response);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Header2 />
            <div style={{ paddingTop: '50px' }}>
                <Banner />
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1>Listes des Patrimoines ici</h1>
                                <Button variant="primary" onClick={openModal}>
                                    Ajouter Un Patrimoine
                                </Button>
                            </div>
                            {userData && (
                                <CreateModalPatrimoine
                                    isOpen={modalIsOpen}
                                    onClose={closeModal}
                                    onCreate={handleCreate}
                                    apiUrl="https://hmdgn.com/presms/patrimoine/"
                                    initialFormData={{
                                        nom: '',
                                        description: '',
                                        quantity: '',
                                        valeur:''
                                        //user: userData.id
                                    }}
                                />
                            )}
                            <ListPatrimoine data={data} setData={setData} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
}

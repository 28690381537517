import React, { useEffect, useState } from "react";
import { useDataContext } from "../../Context/DataProvider";
import { Modal, Button, Spinner, Table, Container, Col, Row, Form } from "react-bootstrap";
import SearchBar2 from "../PageLayout/SearchBar2";
import CreateRecordModal from "./CreateRecordModal";
import moment from "moment";
import "moment/locale/fr";
import CurrencyComponent from "../../Utils/CurrencyComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

export default function ViewModalEleve({ isOpen, onClose, elevesData, totalAmount }) {
  const [loading, setLoading] = useState(false);
  const { academicYears, categories, userData } = useDataContext();
  const [elevepayement, setElevePayement] = useState([]);
  const [filteredElevePayement, setFilteredElevePayement] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalPayementAmount, setTotalPayementAmount] = useState(0);
  const [newPhoto, setNewPhoto] = useState(null);
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);
  const [escompter, setEscompter] = useState(elevesData ? elevesData.escompter : false);

  const handleEscompter = () => {
    const apiUrl = `https://hmdgn.com/presms/eleves/${elevesData.id}/`;
    fetch(apiUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ escompter: !escompter }),
    })
      .then((response) => response.json())
      .then((updatedData) => {
        setEscompter(updatedData.escompter);
      })
      .catch((error) => console.error('Error updating escompter:', error));
  };

  const formatDateToHumanReadable = (date) => {
    return moment(date).locale("fr").fromNow();
  };

  const getYearNameById = (id) => {
    const year = academicYears.find((year) => year.id === id);
    return year ? year.year_name : "N/A";
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openPhotoModal = () => {
    setPhotoModalIsOpen(true);
  };
  const closePhotoModal = () => {
    setPhotoModalIsOpen(false);
  };

  const getCategoryNameById = (id) => {
    const category = categories.find((category) => category.id === id);
    return category ? category.category_name : "N/A";
  };

  const fetchAndSetElevePayement = () => {
    setLoading(true);
    const timestamp = Date.now();

    fetch(`https://hmdgn.com/presms/payement-by-eleve/${elevesData.id}/?timestamp=${timestamp}`)
      .then((response) => response.json())
      .then((fetchedData) => {
        setElevePayement(fetchedData);
        setFilteredElevePayement(fetchedData);
        const totalPayementAmount = fetchedData.reduce(
          (accumulator, eleve) => accumulator + parseFloat(eleve.payement_amount),
          0
        );
        setTotalPayementAmount(totalPayementAmount);
      })
      .catch((error) => console.error("Error fetching eleves data:", error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreate = (newRecord) => {
    setElevePayement((prevElevePayement) => {
      const updatedElevePayement = [...prevElevePayement, newRecord];
      setTotalPayementAmount((prevTotalAmount) => {
        const updatedTotalAmount = prevTotalAmount + parseFloat(newRecord.payement_amount);
        return updatedTotalAmount;
      });

      return updatedElevePayement;
    });
    setFilteredElevePayement((prevFilteredElevePayement) => [...prevFilteredElevePayement, newRecord]);
  };

  useEffect(() => {
    if (isOpen || elevesData) {
      fetchAndSetElevePayement();
    }
  }, [isOpen, elevesData]);

  useEffect(() => {
    console.log("Eleves data by Class:", elevesData);
    console.log("Class Data from ViewModal by eleve:", elevesData);
  }, [elevesData]);

  const handlePhotoUpload = (e) => {
    setNewPhoto(e.target.files[0]);
  };

  const handlePhotoSubmit = () => {
    const apiUrl = `https://hmdgn.com/presms/eleves/${elevesData.id}/`;
    const formData = new FormData();
    formData.append('photo', newPhoto);

    fetch(apiUrl, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((updatedData) => {
        elevesData.photo = updatedData.photo;
        setPhotoModalIsOpen(false);
      })
      .catch((error) => {
        console.error('Error uploading photo:', error);
        alert('Error uploading photo');
      });
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm.trim() === '') {
      setFilteredElevePayement(elevepayement);
    } else {
      const filtered = elevepayement.filter(eleve => 
        (eleve.payement_mensuel && eleve.payement_mensuel.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (eleve.payement_nature && eleve.payement_nature.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (eleve.payement_amount && eleve.payement_amount.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (eleve.payement_type && eleve.payement_type.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (eleve.observation && eleve.observation.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredElevePayement(filtered);
    }
  };
  

  return (
    <div>
      {isOpen && !modalIsOpen && (
        <Modal show={isOpen} onHide={onClose} className="custom-modal-width" size="xl">
          <Modal.Header closeButton className="custom-modal-header">
            <Modal.Title>Matricule: GSHMD{elevesData ? elevesData.id : 'N/A'}</Modal.Title>
          </Modal.Header>
          <div style={{ maxHeight: "600px", overflowY: "auto", padding: "10px" }}>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={6} md={8}>
                    {elevesData ? (
                      <div>
                        <p><strong>First Name:</strong> {elevesData.first_name}</p>
                        <p><strong>Last Name:</strong> {elevesData.last_name}</p>
                        <p><strong>Telephone:</strong> {elevesData.telephone}</p>
                        <p><strong>Address:</strong> {elevesData.address}</p>
                        {userData && userData[0].is_staff && (
                          <Button variant="danger" onClick={handleEscompter}>
                            {escompter ? 'Annuler Escompter' : 'Escompter'}
                          </Button>
                        )}
                      </div>
                    ) : (
                      'N/A'
                    )}
                  </Col>
                  <Col xs={6} md={4}>
                    <div className="header-image" style={{ position: 'relative', cursor: 'pointer' }} onClick={openPhotoModal}>
                      {elevesData ? (
                        <img
                          src={elevesData.photo}
                          style={{
                            width: '100px',
                            height: '100px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            borderRadius: '25px',
                          }}
                          alt="Profile"
                        />
                      ) : (
                        <img src="/images/logo.jpg" alt="Default" />
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row style={{ backgroundColor: "light" }}>
                  <Col xs={6} md={4}>
                    <p style={{ fontSize: '18px', fontWeight: 'bold', alignItems: 'center' }}>Scolarite:</p>
                    <CurrencyComponent amount={totalAmount} currency="GNF" />
                  </Col>
                  <Col xs={6} md={4}>
                    <p style={{ fontSize: '18px', fontWeight: 'bold', alignItems: 'center' }}>Montant Payer:</p>
                    {loading ? (
                      <Spinner animation="border" size="sm" className="ml-2" />
                    ) : (
                      elevesData ? <CurrencyComponent amount={totalPayementAmount} currency="GNF" /> : '0 GNF'
                    )}
                  </Col>
                  <Col xs={6} md={4}>
                    <p style={{ fontSize: '18px', fontWeight: 'bold', alignItems: 'center' }}>Reste a Payer:</p>
                    {loading ? (
                      <Spinner animation="border" size="sm" className="ml-2" />
                    ) : (
                      elevesData && escompter ? 
                        <>
                          <span style={{ color: 'red' }}>Escompter</span>
                          <p>{totalAmount - totalAmount} GNF</p>
                        </> 
                        : (elevesData ? <CurrencyComponent amount={totalAmount - totalPayementAmount} currency="GNF" /> : 'Aucun Payement')
                    )}
                  </Col>
                </Row>
              </Container>
              <hr />
              <SearchBar2 onSearch={handleSearch} />
              <hr />
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Details des Payements</h2>
                <Button variant="info" onClick={openModal}>
                  <FontAwesomeIcon icon={faDollarSign} /> Ajouter
                </Button>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  </div>
                ) : filteredElevePayement && filteredElevePayement.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Mensualite</th>
                        <th>Nature</th>
                        <th>Montant</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Observation</th>
                        <th>Annee Academic</th>
                        {userData && userData[0].is_staff && (
                          <>
                            <th>Mod</th>
                            <th>Sup</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredElevePayement.map((eleve) => (
                        <tr key={eleve.id}>
                          <td>{eleve.id}</td>
                          <td>{eleve.payement_mensuel}</td>
                          <td>{eleve.payement_nature}</td>
                          <td>{eleve.payement_amount}</td>
                          <td>{eleve.payement_type}</td>
                          <td>{formatDateToHumanReadable(eleve.created)}</td>
                          <td>{eleve.observation}</td>
                          <td>{getYearNameById(eleve.academic_year)}</td>
                          {userData && userData[0].is_staff && (
                            <>
                              <td>
                                <Button
                                  variant="success"
                                  size="sm"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>Données non disponibles pour cet eleve</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={onClose}>
                Fermer
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      )}
      <CreateRecordModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        onCreate={(newRecord) => {
          handleCreate(newRecord);
        }}
        apiUrl="/presms/elevepayement/"
        initialFormData={{
          payement_mensuel: '',
          payement_nature: '',
          observation: '',
          payement_amount: '',
          payement_type: '',
          academic_year: '',
          EleveId: elevesData ? elevesData.id : '',
          classroom: elevesData ? elevesData.class_assigned : '', 
        }}
      />
      {photoModalIsOpen && (
        <Modal show={photoModalIsOpen} onHide={closePhotoModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload New Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile">
                <Form.Label>New Photo</Form.Label>
                <Form.Control type="file" onChange={handlePhotoUpload} />
              </Form.Group>
              <Button variant="primary" onClick={handlePhotoSubmit}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

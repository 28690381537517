import React, { useState, useContext } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Table, Button, Pagination } from "react-bootstrap";
import { del } from "../Services/Api.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBar from "./PageLayout/SearchBar.js";
import { DataContext } from "../Context/DataProvider.js";
import CurrencyComponent from "../Utils/CurrencyComponent.js";
import { faEye, faDollarSign, faTrash, faEdit, faVcard } from '@fortawesome/free-solid-svg-icons';
import ViewRecordStaffs from "./Modal/ViewRecordStaffs.js";
import ViewRecordEmp from "./Modal/ViewRecordEmp.js";

export default function ListStaffs({ data }) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { academicYears, categories } = useContext(DataContext);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const formatDateToHumanReadable = (date) => {
    return moment(date).locale("fr").fromNow();
  };

  const handleView = (id) => {
    setSelectedItemId(id);
    setIsViewModalOpen(true);
  };

  const handleCloseView = () => {
    setSelectedItemId(null);
    setIsViewModalOpen(false);
    setIsUpdateModalOpen(false);
  };

  const handleSearch = (filteredData) => {
    setFilteredData(filteredData);
    setCurrentPage(1);
  };

  const handleDelete = (id) => {
    del(`/presms/eleves/${id}`)
      .then(() => {
        // You can update the parent component's data and re-render it
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = Array.isArray(filteredData) && filteredData.length > 0
    ? filteredData.slice(startIndex, endIndex)
    : data.slice(startIndex, endIndex);

  return (
    <div>
      <SearchBar
        data={data}
        onSearch={handleSearch}
        filterFields={['id', 'first_name', 'telephone', 'email']}
        placeholder="Rechercher par id, telephone or nom de l'employer "
        buttonLabel="Rechercher"
      />
      <hr />
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>Telephone</th>
              <th>Titre</th>
              <th>Voir</th>
              <th>Modif</th>
              <th>Sup</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(currentData) && currentData.length > 0 ? (
              currentData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td>{item.telephone}</td>
                  <td>{item.titre ? item.titre : 'N/A'}</td>
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleView(item.id)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </td>
                  <td>
                    <Button variant="success" size="sm" className="mr-2">
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">Données en cours de telechargement. Oops...😕</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination>
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / itemsPerPage)))}
            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          />
        </Pagination>
        <ViewRecordEmp
          isOpen={isViewModalOpen}
          onClose={handleCloseView}
          data={data.find((item) => item.id === selectedItemId)}
        />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDataContext } from "../../Context/DataProvider";
import { Link } from "react-router-dom";

export default function Banner() {
  const { categories, academicYears, userData, school  } = useDataContext();
  
  useEffect(() => {
    // This code will run after the component has re-rendered
    //console.log("Updated userData in Banner", userData);
  }, [userData]); // Make sure to include elevePaymentData in the dependency array


  return (
    <div className="bg-primary text-white text-center py-5">
      <h1 className="display-4">{school && school.length!==0 && school[0].name}</h1>
      <p className="lead">Former les leaders de demain</p>
      <p>
        <Link to="/classroom">
          <Button variant="light">Voir les Classes</Button>
        </Link>
      </p>
      <div>
        {academicYears.length > 0 ? ( // Check if academicYears is available
          <p>Annee Academic: {academicYears[1].year_name}</p>
        ) : (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}


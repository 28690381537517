import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const SearchBar2 = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <Form inline>
      <Form.Control
        type="text"
        placeholder="Recherche en tapant les initials..."
        className="mr-sm-2"
        value={searchTerm}
        onChange={handleInputChange}
      />
    </Form>
  );
};

export default SearchBar2;

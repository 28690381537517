import React, { useState } from "react";
import { Card, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit, faAdd, faHome, faPerson, faSchool } from '@fortawesome/free-solid-svg-icons';
import CurrencyComponent from "../Utils/CurrencyComponent";

export default function ListStatistiques({ elevepayement, profpayement, staffpayement,otherpayement, dataEleve}) {
  const [countEscompter, setCountEscomptter] = useState([])
  //const filteredUsers = dataEleve.filter(eleve => eleve.escompter === true);
  //setCountEscomptter(filteredUsers.length)
  const filteredUsers = dataEleve.filter(eleve => eleve.escompter === true);
  const count = filteredUsers.length;

  return (
    <Container className="my-4">
      <Row xs={1} md={2} lg={3} xl={4} className="g-4">
        {/* Additional Card */}
        <Col className="mb-3">
          <Card className="h-100 shadow">
            <Card.Header>
                    <FontAwesomeIcon icon={faDollarSign} size="5x" className="card-img-top" />
                  </Card.Header>
              <Card.Body>
              <Card.Title>Nombre des eleves</Card.Title>
              <Card.Text>Total inscrits : {dataEleve.length}</Card.Text>
              <Card.Text>Total Escompter : {count}</Card.Text>
              <Card.Title>Payement des eleves</Card.Title>
              <Card.Text><CurrencyComponent amount={elevepayement} currency="GNF" textColor="#006400" /></Card.Text>
              {/* Add more details as needed */}
            </Card.Body>
            {/*<Card.Footer>
              <Button variant="info">Actualiser</Button>
            </Card.Footer>
            */}
          </Card>
        </Col>
         {/* Additional Card */}
         <Col className="mb-3">
          <Card className="h-100 shadow">
            <Card.Header>
                    <FontAwesomeIcon icon={faPerson} size="5x" className="card-img-top" />
                  </Card.Header>
              <Card.Body>
              <Card.Title>Salaire Global des profs</Card.Title>
              <Card.Text><CurrencyComponent amount={profpayement} currency="GNF" textColor="#ff0000" /></Card.Text>
              {/* Add more details as needed */}
            </Card.Body>
            {/*<Card.Footer>
              <Button variant="info">Actualiser</Button>
            </Card.Footer>
            */}
          </Card>
        </Col>
         {/* Additional Card */}
         <Col className="mb-3">
          <Card className="h-100 shadow">
            <Card.Header>
                    <FontAwesomeIcon icon={faSchool} size="5x" className="card-img-top" />
                  </Card.Header>
              <Card.Body>
              <Card.Title>Salaire du personnels</Card.Title>
              {!staffpayement ? (
              <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
              </Spinner>
              ) : (
              <Card.Text>
                <CurrencyComponent amount={staffpayement} currency="GNF" textColor="#ff0000" />
              </Card.Text>
              )}
              {/* Add more details as needed */}
            </Card.Body>
            {/*<Card.Footer>
              <Button variant="info">Actualiser</Button>
            </Card.Footer>
            */}
          </Card>
        </Col>
         {/* Additional Card */}
         <Col className="mb-3">
          <Card className="h-100 shadow">
            <Card.Header>
                    <FontAwesomeIcon icon={faSchool} size="5x" className="card-img-top" />
                  </Card.Header>
              <Card.Body>
              <Card.Title>Autres depenses</Card.Title>
              {!otherpayement ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ):(
                <Card.Text><CurrencyComponent amount={otherpayement} currency="GNF" textColor="#ff0000" /></Card.Text>
              )}
            </Card.Body>
            {/*<Card.Footer>
              <Button variant="info">Actualiser</Button>
            </Card.Footer>
            */}
          </Card>
        </Col>
         {/* Additional Card */}
         <Col className="mb-3">
          <Card className="h-100 shadow">
            <Card.Header>
                    <FontAwesomeIcon icon={faSchool} size="2x" className="card-img-top" />
                  </Card.Header>
              <Card.Body>
              <Card.Title>Resultats Generales</Card.Title>
              <Card.Text>Salaires Prof: <CurrencyComponent amount={profpayement} currency="GNF" textColor="#ff0000" /></Card.Text>
              <Card.Text>Salaires staff: <CurrencyComponent amount={staffpayement} currency="GNF" textColor="#ff0000" /></Card.Text>
              <Card.Text>Autres depenses: <CurrencyComponent  amount={otherpayement} currency="GNF" textColor="#ff0000"/></Card.Text>
              <Card.Text>Payement Eleves: <CurrencyComponent amount={elevepayement}  currency="GNF" textColor="#006400" /></Card.Text>
              <Card.Text>Resultat 2023-2024: <CurrencyComponent amount={elevepayement-(otherpayement+staffpayement+profpayement)} currency="GNF" /></Card.Text>

              {/* Add more details as needed */}
            </Card.Body>
            {/*<Card.Footer>
              <Button variant="info">Actualiser</Button>
            </Card.Footer>
            */}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

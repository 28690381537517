import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../Context/DataProvider";
import { Modal, Button, Form , Spinner} from "react-bootstrap";
import '../../Style/CreateRecordModal.css';
import { post } from "../../Services/Api"; // Import the post function from your Api.js
import FrenchTranslator from "../../Utils/FrenchTranslator";


export default function CreateModalStaffs({
  isOpen,
  onClose,
  onCreate,
  updateData, // New prop to update elevesData in ViewRecordModal
  apiUrl, // The API URL for the POST request
  initialFormData, // An object with the initial form data structure
  onCreateSuccess, // New prop for success callback
}) {
  const [formData, setFormData] = useState(initialFormData);
  const [file, setFile] = useState(null);
  const { academicYears, categories, classRoom, userData } = useContext(DataContext);
  const [loading, setLoading] = useState(false);

  console.log("userdata.id in in Modal", userData?.id);

  console.log("formData before any change", formData);
  console.log("initialFormData before any change", initialFormData);

  // Update formData when the component mounts
    useEffect(() => {
      setFormData(initialFormData);
    }, [initialFormData]);
  
  const handleChange = (e) => {
    const { name, value, files , options} = e.target;

    if (name === "photo" && files.length > 0) {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
      setFile(files[0]);
    }
    else {
      // Handle text inputs
      setFormData((prevData) => ({
        ...prevData,
        //[name]: name === "payement_amount" ? parseFloat(value) : value,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
  
    console.log('API URL:', apiUrl);
    console.log('Inside the submit formData:', formData);
  
    setLoading(true);
  
    try {
      const data = await post(apiUrl, formData); // Use the custom post function to send a POST request
  
      console.log('Data sent successfully with CreateRecordModal and here:', data);
      // Clear the form after successful submission
      setFormData(initialFormData);
      setFile(null);
      // And clear data
      onCreate(data);
      updateData(data);
      setLoading(false);
      onClose();
  
      // Call the success callback if provided
      if (onCreateSuccess) {
        onCreateSuccess();
      }
    } catch (error) {
      console.error("Error:", error);
      // Log the full response details
      console.log('Response Detail:', error.response); // log the response details
      setLoading(false);
      onClose();
    }
  };
  

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton className="custom-modal-header" style={{backgroundColor:'green'}}>
        <Modal.Title>Ajouter un employer</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
      }}>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <div style={{ height: "100%", overflowY: "auto", padding: "20px" }}>
          <Form onSubmit={handleSubmit} >
            {Object.keys(initialFormData).map((field) => (
              <Form.Group key={field}>
                <Form.Label><FrenchTranslator input={field} />:</Form.Label>
                {field === "academic_year" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="custom-form-control"
                  >
                    <option value="">Select Academic Year</option>
                    {academicYears.length > 0 && // Check if academicYears is available
                      academicYears.map((year) => (
                        <option key={year.id} value={year.id}>
                          {year.year_name}
                        </option>
                      ))}
                  </Form.Control>
                ) : field === "payement_mensuel" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="custom-form-control"
                  >
                    <option value="">Selectioner le mois</option>
                    <option value="janvier">Janvier</option>
                    <option value="fevrier">Fevrier</option>
                    <option value="mars">Mars</option>
                    <option value="avril">Avril</option>
                    <option value="mai">Mai</option>
                    <option value="juin">Juin</option>
                    <option value="juillet">Juillet</option>
                    <option value="aout">Aout</option>
                    <option value="septembre">Septembre</option>
                    <option value="octobre">Octobre</option>
                    <option value="novembre">Novembre</option>
                    <option value="decembre">Decembre</option>
                    <option value="autres">Autres</option>
                    <option value="cumul">Cumul</option>
                  </Form.Control>
                ) : field === "payement_type" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="custom-form-control"
                  >
                    <option value="">Selectioner payement type</option>
                    <option value="inscription">Inscription</option>
                    <option value="reinscription">Reinscription</option>
                    <option value="scolarite">Scolarite</option>
                    <option value="autres">Autres</option>
                  </Form.Control>
                ) : field === "titre" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="custom-form-control"
                  >
                    <option value="">Selectioner le titre</option>
                    <option value="directeur">Directeur</option>
                    <option value="securite">Securite</option>
                    <option value="netoyeuse">Netoyeuse</option>
                    <option value="autres">Autres</option>
                  </Form.Control>
                ) :
                 field === "category" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    value={formData[field]}
                    //readOnly
                    onChange={handleChange}
                    className="custom-form-control"
                  >
                    <option value="">Selectioner votre categories</option>
                    {categories.length > 0 && // Check if categories are available
                      categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.category_name}
                        </option>
                      ))}
                  </Form.Control>
                ) : field === "class_assigned" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    //defaultValue={formData[field] || initialFormData.class_assigned}
                    className="custom-form-control"
                  >
                    <option value="">Select Category</option>
                    {classRoom.length > 0 &&
                      classRoom.map((classRoom) => (
                        <option key={classRoom.id} value={classRoom.id}>
                          ID {classRoom.id} - {classRoom.class_name}
                        </option>
                      ))}
                  </Form.Control>
                ) : field === "classes_prof_enseigner" ? (
                  <Form.Control
                    as="select"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="custom-form-control"
                    multiple  // Add the 'multiple' attribute for multi-selection
                  >
                    {classRoom.length > 0 && // Check if classes are available
                      classRoom.map((classRoom) => (
                        <option key={classRoom.id} value={classRoom.id}>
                          {classRoom.class_name}
                        </option>
                      ))}
                  </Form.Control>
                ) : field === "photo" ? (
                  <Form.Group key={field}>
                    <Form.Control
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      name={field}
                      onChange={handleChange}
                      className="custom-form-control"
                    />
                  </Form.Group>
                ) : field === "date_of_birth" ? (
                  <Form.Group key={field}>
                    <Form.Control
                      type="date"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange} // Add a separate handler for file input
                      className="custom-form-control"
                    />
                  </Form.Group>
                ) : (
                  <Form.Control
                    type="text"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="custom-form-control"
                  />
                )}
              </Form.Group>
            ))}
            <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
              <Button variant="danger" onClick={onClose}>
                  Retour
              </Button>
              <Button variant="primary" type="submit">
                  {loading ? <Spinner animation="border" size="sm" /> : 'Envoyer'}
              </Button>
            </div>
          </Form>
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

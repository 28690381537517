import React from 'react';

export default function FrenchTranslator({ input }) {
  const translations = {
    hello: 'Bonjour',
    world: 'le monde',
    first_name:'Nom',
    last_name:'Prenom',
    blood_group: 'Groupe Sanguin',
    academic_year:'Annee Academic',
    class_assigned:'Classe',
    email:'Email',
    telephone:'Telephone',
    address:'Adresse',
    date_of_birth:'Date de Naissance',
    previous_school_name:'Ecole Precedente',
    photo:'Photo',
    class_name:'Nom de la Classe',
    category:'Category',
    total_amount: 'Scolarite',
    payement_mensuel: 'Mois de payement',
    payement_nature:'Nature de payement',
    observation:'Observation',
    payement_amount:'Montant',
    payement_type:'Type Payement',
    EleveId:'Identite'
    // Add more translations here
  };

  const wordPattern = /[a-zA-Z_]+/g;
  const words = input.match(wordPattern);

  if (words) {
    const translatedWords = words.map(word => {
      const lowerWord = word.toLowerCase();
      return translations[lowerWord] || word;
    });

    const translatedOutput = translatedWords.join(' ');
    return <span>{translatedOutput}</span>;
  } else {
    return <span>{input}</span>;
  }
}

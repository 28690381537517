import React from 'react';

const CurrencyComponent = ({ amount, currency, textColor }) => {
  const formattedCurrency = new Intl.NumberFormat('fr-GN', {
    style: 'currency',
    currency: currency,
  }).format(amount);

  const containerStyle = {
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    color: textColor || '#3366cc', // Use the provided textColor or a default color
    fontWeight: 'bold',
  };

  return (
    <span style={containerStyle}>
      {formattedCurrency}
    </span>
  );
};

export default CurrencyComponent;

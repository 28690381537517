import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner, Image } from 'react-bootstrap';
import { post } from '../Services/Api';
import { DataContext } from '../Context/DataProvider';
import Footer from './PageLayout/Footer';

export default function Login() {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { school } = useContext(DataContext); // Use your DataContext
    if(school){
        console.log("this is the shool information", school[0])
    }
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        // Extract the entered username
        const enteredUsername = formData.username;

        // Construct the request payload
        const payload = {
            username: formData.username,
            password: formData.password,
        };

        // Make a POST request to the authentication endpoint api-auth
        post('/api-auth/', payload)
        //post('/presms/api-token-auth/', payload)
            .then((data) => {
                // Store the token in localStorage
                localStorage.setItem('authToken', data.token);
                console.log("data from login", data)
                // Store the entered username in localStorage
                localStorage.setItem('enteredUsername', enteredUsername);
                //localStorage.setItem('user Id', data.user_id);
                //localStorage.setItem('Username', data.user.username);
                // Redirect to the home page upon successful login
                window.location.href = '/home';
            })
            .catch((error) => {
                setError('Login failed. Please check your credentials.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div style={{ background: '#f4f4f4', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
             {/* Header */}
             <header className="bg-primary shadow" style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <Container className="py-3">
                    <Row className="align-items-center">
                        {/* Left side with school logo */}
                        <Col md={6} className="d-flex justify-content-md-start justify-content-center">
                            <img
                                src="/images/logo.jpg"
                                alt="My Logo"
                                className="img-fluid"
                                style={{ width: '60px', height: '40px', borderRadius: '10%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
                            />
                        </Col>

                        {/* Right side with school name */}
                        <Col md={6} className="text-md-right text-center">
                            <h1 className="text-white" style={{ fontSize: '2rem' }}>
                                Plateforme de Gestion Scolaire
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </header>
             {/* Main Content */}
             <br /> <br />
             <main>
                <Container className="my-5" style={{ backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")' }}>
                    <Row className="d-flex">
                        <Col xs={12} md={6} className="mb-4 d-flex flex-column">
                            <div className="login-form p-4" style={{ backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <h2 className="mb-4">Connexion</h2>
                                <p>Voici votre portail de connexion. Veuillez entrer votre <strong>nom d'utilisateur et votre mot de passe </strong>et ne les communiquez à personne.</p>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="username">
                                        <Form.Label>Nom d'utilisateur</Form.Label>
                                        <Form.Control type="text" name="username" placeholder="Entrer votre nom d'utilisateur" value={formData.username} onChange={handleChange} />
                                    </Form.Group>

                                    <Form.Group controlId="password">
                                        <Form.Label>Mote de passe</Form.Label>
                                        <Form.Control type="password" name="password" placeholder="Entrer votre mot de passe" value={formData.password} onChange={handleChange} />
                                    </Form.Group>
                                    <br /><br />
                                    <Button variant="primary" type="submit" className='w-100' disabled={loading}>
                                        {loading ? <Spinner animation="border" size="sm" /> : 'Connecter'}
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="mb-4 d-flex flex-column">
                            <div className="login-image" >
                                <img src="images/school.jpeg" alt="Login" className="img-fluid" style={{ backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>

                <Footer />
        </div>
    );
};

import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';

export default function SearchBar({
  data,
  onSearch,
  filterFields = [], // Array of fields to filter on
  placeholder = 'Rechercher par Matricule ou par nom',
  buttonLabel = 'Search'
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // Use the callback to access the updated searchTerm value
    setSearchTerm((prevSearchTerm) => {
      console.log("Updated searchTerm:", prevSearchTerm);
      // Trigger the search logic when the searchTerm changes
      handleSearch();
      return prevSearchTerm; // Return the updated searchTerm to set it immediately
    });
  };
  
  const handleSearch = () => {
    try {
      if (data && Array.isArray(data)) {
        setSearchTerm((prevSearchTerm) => {
          const newFilteredData = prevSearchTerm
            ? data.filter((item) => {
                return filterFields.some((field) =>
                  item[field]
                    ?.toString()
                    .toLowerCase()
                    .includes(prevSearchTerm.toLowerCase())
                );
              })
            : data;
  
          // Pass the filtered data to the parent component
          onSearch(newFilteredData);
          return prevSearchTerm; // Return the updated searchTerm to set it immediately
        });
      }
    } catch (error) {
      console.error('Error filtering data:', error);
    }
  };
  
  return (
    <Form inline={true.toString()}>
      <FormControl
        type="text"
        placeholder={placeholder}
        className="mr-sm-2 shadow-sm"
        value={searchTerm}
        onChange={handleChange}
      />
      <Button variant="success" onClick={handleSearch}>
        {buttonLabel}
      </Button>
    </Form>
  );
}

import React, { useState, useEffect } from 'react';
import Footer from '../Components/PageLayout/Footer';
import Banner from '../Components/PageLayout/Banner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';

export default function ClassRoomByEleve() {
    
    return (
            <>
                <div className="container mt-3">
                  <Banner/>
                    <Container>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h1>Listes des Eleve by class</h1>
                                    <Button variant="primary">
                                        Ajouter Une Classe
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
                <Footer />
            </>
    );
}

import React, { useEffect, useState, useContext } from "react";
import { useDataContext } from "../../Context/DataProvider";
import { Modal, Button, Spinner, Table, Container, Col, Row, Form } from "react-bootstrap";
import SearchBar from "../PageLayout/SearchBar";
import { DataContext } from "../../Context/DataProvider";
import CreateModalProf from "./CreateModalProf";
import PayementRecord from "./PayementRecord";
import moment from "moment";
import "moment/locale/fr";
import CurrencyComponent from "../../Utils/CurrencyComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

export default function ViewRecordProf({ isOpen, onClose, data}) {
  //const [eleveInfo, setEleveInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  //const { academicYears, categories, elevePaymentData, setElevePaymentData } = useDataContext(); // Use your DataContext
  const { academicYears, categories, classRoom } = useContext(DataContext);
  const [elevepayement, setElevePayement] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalPayementAmount, setTotalPayementAmount] = useState(0);
  const [newPhoto, setNewPhoto] = useState(null);
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);
  //const [shouldFetch, setShouldFetch] = useState(false);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");

  // Handle the change in the select input
  const handleAcademicYearChange = (e) => {
    setSelectedAcademicYear(e.target.value);
    // Perform any additional actions based on the selected academic year
    // For example, you might want to filter the displayed payments.
  };
  const titleStyle = {
    fontSize: '18px', // Set the font size
    fontWeight: 'bold', // Make the text bold
    alignItems: 'center',
    // Add any other styles you want to apply
  };

  const getYearNameById = (id) => {
    // Find the academic year by ID and return its year_name
    const year = academicYears.find((year) => year.id === id);
    return year ? year.year_name : "N/A";
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  
  const getCategoryNameById = (id) => {
    // Find the category by ID and return its category_name
    const category = categories.find((category) => category.id === id);
    return category ? category.category_name : "N/A";
  };
  // Map class IDs to class names
  const getClassNamesByIds = (classIds) => {
    return classIds.map((classId) => {
      const matchingClass = classRoom.find((cls) => cls.id === classId);
      return matchingClass ? matchingClass.class_name : "N/A";
    });
  };
  const handleSearch = () => {
    console.log("Value filteredData in Parent Classrrom cpm");
  };
  // Function to fetch and set elevepayement data
const fetchAndSetElevePayement = () => {
  setLoading(true);
  //Generate a unique timestamp to ensure a fresh request
  const timestamp = Date.now();

  fetch(`https://hmdgn.com/presms/api/professeurpayement/${data.id}/?timestamp=${timestamp}`)
    .then((response) => response.json())
    .then((fetchedData) => {
      setElevePayement(fetchedData);
      const totalPayementAmount = fetchedData.reduce(
        (accumulator, eleve) => accumulator + parseFloat(eleve.total_salary),
        0
      );
      setTotalPayementAmount(totalPayementAmount);
    })
    .catch((error) => console.error("Error fetching eleves data:", error))
    .finally(() => {
      setLoading(false);
    });
};

// Function to handle the creation of a new record
const handleCreate = (newRecord) => {
  setElevePayement((prevElevePayement) => {
    const updatedElevePayement = [...prevElevePayement, newRecord];
    // Update totalPayementAmount state
    setTotalPayementAmount((prevTotalAmount) => {
      const updatedTotalAmount = prevTotalAmount + parseFloat(newRecord.total_salary);
      return updatedTotalAmount;
    });

    return updatedElevePayement;
  });
};

// Fetch data when the component mounts
useEffect(() => {
  if (isOpen || data) {
    fetchAndSetElevePayement();
  }
}, [isOpen, data]);

// Log changes in data
useEffect(() => {
  console.log("Eleves data by Class:", data);
  console.log("Class Data from ViewModal by eleve:", data);
}, [data]);

// Rest of your component code...
useEffect(() => {
  console.log("Eleves data by Class:", data);
  console.log("Class Data from ViewModal by eleve:", data);
}, [data]);

const openPhotoModal = () => {
  setPhotoModalIsOpen(true);
};
const closePhotoModal = () => {
  setPhotoModalIsOpen(false);
};

const handlePhotoUpload = (e) => {
  setNewPhoto(e.target.files[0]);
};
const handlePhotoSubmit = () => {
  if (!newPhoto) {
    alert('Please select a photo to upload');
    return;
  }

  const apiUrl = `https://hmdgn.com/presms/professeurs/${data.id}/`;
  const formData = new FormData();
  formData.append('photo', newPhoto);

  fetch(apiUrl, {
    method: 'PUT',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((updatedData) => {
      data.photo = updatedData.photo;
      setPhotoModalIsOpen(false);
    })
    .catch((error) => {
      console.error('Error uploading photo:', error);
      alert('Error uploading photo');
    });
};

  return (
    <div>
      {isOpen && !modalIsOpen && ( // Render ViewModalEleve only if isOpen and !modalIsOpen
      <Modal show={isOpen} onHide={onClose} className="custom-modal-width" size="xl">
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Matricule Du Professeur: PHMD{data ? data.id : 'N/A'}</Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "600px", overflowY: "auto", padding: "10px" }}>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={8}>
                {data ? (
                  <div>
                    <p><strong>First Name:</strong> {data.first_name}</p>
                    <p><strong>Last Name:</strong> {data.last_name}</p>
                    <p><strong>Telephone:</strong> {data.telephone}</p>
                    <p><strong>Address:</strong> {data.address}</p>
                    <p><strong>Sujet Enseigner: </strong> {data.subject_taught ? data.subject_taught :'n/a'}</p>
                    <p><strong>Diplome obtenu:</strong> {data.diploma_obtained ? data.diploma_obtained: 'n/a' }</p>
                    <p>
                    <strong>Classe Enseigner:</strong>{" "}
                    {data.classes_prof_enseigner
                      ? getClassNamesByIds(data.classes_prof_enseigner).join(", ")
                      : "n/a"}
                  </p>
                  <p><strong>Details:</strong> {data.obvervation? data.obvervation:"n/a"}</p>
                  </div>
                ) : (
                  'N/A'
                )}
              </Col>
              <Col xs={6} md={4}>
                <div className="header-image" style={{ position: 'relative', cursor: 'pointer' }} onClick={openPhotoModal}>
                  {/* Conditionally render the image */}
                  {data.photo ? (
                    <img
                      src={data.photo}
                      style={{
                        width: '100px',
                        height: '100px',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        borderRadius: '25px',
                      }}
                      alt="Profile"
                    />
                  ) : (
                    <img src="/images/avatar.jpeg" 
                      style={{
                          width: '100px',
                          height: '100px',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          borderRadius: '25px',
                        }}
                      alt="photo" />
                  )}
                  
                </div>         
              </Col>
            </Row>
            <hr />
            <Row style={{ backgroundColor: "light" }}>
              <Col xs={6} md={4}>
                <p style={titleStyle}>Montant Total</p>
                  <CurrencyComponent amount={totalPayementAmount} currency="GNF" />
                
              </Col>
              {/*<Col xs={6} md={4}>
                <p style={titleStyle}>Montant Payer:</p>
                  {loading ? (
                    // Display a Spinner while loading
                    <Spinner animation="border" size="sm" className="ml-2" />
                  ) : (
                    data ? <CurrencyComponent amount={totalPayementAmount} currency="GNF" /> : '0 GNF'
                  )}
                
              </Col>
              <Col xs={6} md={4}>
                <p style={titleStyle}>Reste a Payer:</p>
                {loading ? (
                  // Display a Spinner while loading
                  <Spinner animation="border" size="sm" className="ml-2" />
                ) : (
                  data && data.escompter ? 
                    <>
                      <span style={{ color: 'red' }}>Escompter</span>
                      <p>{10000 - 5000} GNF</p>
                    </> 
                    : (data ? <CurrencyComponent amount={100000 - totalPayementAmount} currency="GNF" /> : 'Aucun Payement')
                )}
              </Col>*/}
            </Row>
          </Container>
          <hr />
          <SearchBar onSearch={handleSearch} data={data} />
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <h4
                style={{
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  padding: "10px",
                  color: "#006400",
                }}
              >
                Details Payement
              </h4>
              <Form.Group as={Row} className="mb-0 ml-2">
                <Form.Label column sm="auto" className="mr-2"></Form.Label>
                <Col sm="auto">
                  <Form.Control
                    as="select"
                    size="sm"
                    style={{
                      //boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      //padding: "10px",
                      color: "#006400",
                    }}
                    value={selectedAcademicYear}
                    onChange={handleAcademicYearChange}
                  >
                    <option value="">Toutes les années académiques</option>
                    {academicYears.map((year) => (
                      <option key={year.id} value={year.id}>
                        {year.year_name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            </div>
            <Button variant="info" size="sm" onClick={openModal}>
              <FontAwesomeIcon icon={faDollarSign} /> Ajouter
            </Button>
          </div>
          <div className="table-responsive">
            {loading ? (
              // Display a Spinner while loading
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only"></span>
                </Spinner>
              </div>
            ) : elevepayement && elevepayement.length > 0 ? (
              // Render the details of the selected eleve-by-class as a table

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Mensualite</th>
                    <th>Nature</th>
                    <th>Montant</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Observation</th>
                    {/*<th>Voir</th>*/}
                    <th>Mod</th>
                    <th>Sup</th>
                    {/* Add more table headers as needed */}
                  </tr>
                </thead>
                <tbody>
                  {elevepayement.map((eleve) => (
                    <tr key={eleve.id}>
                      <td>{eleve.id}</td>
                      <td>{eleve.payement_mensuel}</td>
                      <td>{eleve.payement_nature}</td>
                      <td><CurrencyComponent amount={eleve.total_salary} currency="GNF" /></td>
                      <td>{eleve.payement_type}</td>
                      <td>{new Date(eleve.created).toLocaleDateString()}</td>
                      <td>{eleve.observation}</td>
                      {/*<td>
                    <Button
                      variant="info"
                      size="sm"
                      className="mr-2"
                      //onClick={() => handleView(item.id)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </td>*/}
                      <td>
                        <Button
                          variant="success"
                          size="sm"
                          className="mr-2"
                        //onClick={() => handleUpdate(item.id)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"

                        //onClick={() => handleDelete(item.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                      {/* Add more table cells as needed */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>Données non disponibles...!</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={onClose}>
            Fermer
          </Button>
        </Modal.Footer>
        </div>
      </Modal>
      )}
      <CreateModalProf
          isOpen={modalIsOpen}
          onClose={closeModal}
          onCreate={(newRecord) => {
            handleCreate(newRecord);
            //fetchAndSetElevePayement(); // Fetch and update elevepayement after successful creation
          }}
          //updateData={(newRecord) =>  setElevePayement([...elevepayement, newRecord])}
          apiUrl="/presms/professeurpayements/"
          initialFormData={{
            payement_mensuel: '',
            payement_nature: '',
            observation: '',
            payement_type: '',
            payment_method: '',
            academic_year: '',
            professeurId:data ? data.id : '',
          }}
          //onCreateSuccess={() => fetchAndSetElevePayement()}
        />
      {photoModalIsOpen && (
        <Modal show={photoModalIsOpen} onHide={closePhotoModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload New Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile">
                <Form.Label>New Photo</Form.Label>
                <Form.Control type="file" onChange={handlePhotoUpload} />
              </Form.Group>
              <Button variant="primary" onClick={handlePhotoSubmit}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>

  );
}

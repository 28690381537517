// api.js

const BASE_URL = 'https://hmdgn.com'; // Your API base URL
//const BASE_URL = 'http://127.0.0.1:8000'

// Function to make a GET request
export async function get(endpoint) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`GET request failed: ${error.message}`);
  }
}

// Function to make a POST request
export async function post(endpoint, data) {
  try {
    const formData = new FormData();

    // Append JSON data
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    console.log('POST URL:', `${BASE_URL}${endpoint}`);
    console.log('POST Data Inside the API.js:', formData);
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        // No need to set Content-Type; it will be set automatically by FormData
        //'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/json',
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    throw new Error(`POST request failed: ${error.message}`);
  }
}




// Function to make a PUT request
export async function put(endpoint, data) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`PUT request failed: ${error.message}`);
  }
}

// Function to make a DELETE request
export async function del(endpoint) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Check if the response has content before attempting to parse JSON
    if (response.status === 204 || response.headers.get("Content-Length") === "0") {
      // 204 No Content or Content-Length header is 0, indicating no content.
      console.log("Deletion successful, no content to return.");
      return null;  // Return null or an appropriate value indicating success with no content
    }
    // If there is content, parse and return it as JSON
    return await response.json();
  } catch (error) {
    throw new Error(`DELETE request failed: ${error.message}`);
  }
}


// Export additional utility functions if required

// Function to make a PATCH request
export async function patch(endpoint, data) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`PATCH request failed: ${error.message}`);
  }
}

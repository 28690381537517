import React, { useState, useEffect } from 'react';
import Header2 from '../Components/PageLayout/Header2';
import Footer from '../Components/PageLayout/Footer';
import SearchBar from '../Components/PageLayout/SearchBar';
import ListProfesseurs from '../Components/ListProfesseurs';
import ListStaffs from '../Components/ListStaffs';
import ListOthersCharges from '../Components/ListOthersCharges';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import CreateRecordModal from '../Components/Modal/CreateRecordModal';
import { get } from '../Services/Api'; // Import the 'get' and others function from your api.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons';
import CreateModalProf from '../Components/Modal/CreateModalProf';
import CreateModalStaffs from '../Components/Modal/CreateModalStaffs';
import CreateModalCharge from '../Components/Modal/CreateModalCharge';

export default function PersonelHome() {
    const [modalIsOpenEmp, setModalIsOpenEmp] = useState(false);
    const [modalIsOpenCharge, setModalIsOpenCharge] = useState(false);
    const [modalIsOpenProf, setModalIsOpenProf] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [professeursData, setProfesseursData] = useState([]);
    const [staffsData, setStaffsData] = useState([]);
    const [otherscharges, setOthersCharges] = useState([]);
    const [selectedTab, setSelectedTab] = useState('professeurs');

    const handleDataUpdate = (updatedRecord) => {
        // Update the data in the parent component
        setProfesseursData((prevData) => {
          const index = prevData.findIndex((item) => item.id === updatedRecord.id);
          if (index !== -1) {
            const newData = [...prevData];
            newData[index] = updatedRecord;
            return newData;
          }
          return prevData;
        });
      };
      const handleDataUpdateCharge = (updatedRecord) => {
        // Update the data in the parent component
        setOthersCharges((prevData) => {
          const index = prevData.findIndex((item) => item.id === updatedRecord.id);
          if (index !== -1) {
            const newData = [...prevData];
            newData[index] = updatedRecord;
            return newData;
          }
          return prevData;
        });
      };
    const openModalProf = () => {
        setModalIsOpenProf(true);
    };
    const closeModalProf = () => {
        setModalIsOpenProf(false);
    };
    const openModalEmp = () => {
        setModalIsOpenEmp(true);
    };

    const closeModalEmp = () => {
        setModalIsOpenEmp(false);
    };

    const openModalCharge = () => {
        setModalIsOpenCharge(true);
    };

    const closeModalCharge = () => {
        setModalIsOpenCharge(false);
    };
    const fetchData = () => {
        setIsLoading(true);
        // Generate a unique timer value (e.g., timestamp or random number)
        const timer = Date.now(); // You can use another method to generate a unique timer

        switch (selectedTab) {
            case 'professeurs':
                get(`/presms/professeurs/?timer=${timer}`)
                    .then((response) => {
                        setProfesseursData(response);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.error('Error:', error);
                    });
                break;
            case 'staffs':
                get(`/presms/staff_personnels/?timer=${timer}`)
                    .then((response) => {
                        setStaffsData(response);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.error('Error:', error);
                    });
                break;
            case 'otherscharges':
                get(`/presms/otherscharges/?timer=${timer}`)
                .then((response) => {
                    setOthersCharges(response);
                    console.log("others charges her in the tabs", otherscharges);
                    setIsLoading(false);
                    })
                .catch((error) => {
                    setIsLoading(false);
                    console.error('Error:', error);
                    });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        console.log('useEffect triggered with selectedTab:', selectedTab);
        fetchData();
    }, [selectedTab]);

    return (
        <>
        <Header2 />
    
        <div className="container mt-3" style={{paddingTop:'70px'}}>
            
            <div className="row">
                <div className="col">
                    <Tabs
                        defaultActiveKey="professeurs"
                        activeKey={selectedTab}
                        onSelect={(tab) => setSelectedTab(tab)}
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        style={{ backgroundColor: '#dddd' }}
                    >
                    <Tab eventKey="professeurs" title="Professeurs">
                        {/* Add button */}
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 
                                style={{ 
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', 
                                        padding: '10px',
                                        color: '#006400', 
                                }}>
                            Listes des professeurs</h3>
                            <Button variant="primary" onClick={openModalProf}>
                                <FontAwesomeIcon icon={faAdd} /> Ajouter
                            </Button>
                        </div>
                        {/* Dislay data 
                        <ListProfesseurs data={data} isLoading={isLoading} />*/}
                        <ListProfesseurs data={professeursData} isLoading={isLoading} onCreate={handleDataUpdate} />
                        {/*This is for professeurs*/}
                        <CreateRecordModal
                            isOpen={modalIsOpenProf}
                            onClose={closeModalProf}
                            onCreate={(newRecord) => setProfesseursData([...professeursData, newRecord])}
                            updateData={(newRecord) =>  setProfesseursData([...professeursData, newRecord])}
                            apiUrl="/presms/professeurs/"
                            initialFormData={{
                                first_name: '',
                                    last_name: '',
                                    date_of_birth: '',
                                    address: '',
                                    telephone: '',
                                    blood_group: '',
                                    email: '',
                                    previous_school_name: '',
                                    academic_year: '',
                                    classes_prof_enseigner: '',
                                    obvervation: '',
                                    //photo: '',

                            }}
                        />
                    </Tab>
                    <Tab eventKey="staffs" title="Autres Employees">
                         {/* Add button */}
                         <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 
                            style={{ 
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', 
                                        padding: '10px',
                                        color: '#006400', 
                                    }}>
                            Autres employers</h3>
                            <Button variant="primary" onClick={openModalEmp}>
                                <FontAwesomeIcon icon={faAdd} /> Ajouter
                            </Button>
                        </div>
                        {/* Dislay data */}
                            <ListStaffs data={staffsData} isLoading={isLoading} />
                        {/* The create Modal */}
                        {/*This is for staffs and utres employers*/}
                        <CreateModalStaffs
                            isOpen={modalIsOpenEmp}
                            onClose={closeModalEmp}
                            onCreate={(newRecord) => setStaffsData([...staffsData, newRecord])}
                            updateData={(newRecord) =>  setStaffsData([...staffsData, newRecord])}
                            apiUrl="/presms/staff_personnels/"
                            initialFormData={{
                                first_name: '',
                                last_name: '',
                                date_of_birth: '',
                                address: '',
                                telephone: '',
                                blood_group: '',
                                email: '',
                                academic_year: '',
                                titre:'',
                                responsibilities: '',
                            }}
                        />
                    </Tab>
                    <Tab eventKey="otherscharges" title="Autres Charges">
                         {/* Add button */}
                         <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 
                            style={{ 
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', 
                                        padding: '10px',
                                        color: '#006400', 
                                    }}>
                            Autres Charges</h3>
                            <Button variant="primary" onClick={openModalCharge}>
                                <FontAwesomeIcon icon={faAdd} /> Ajouter
                            </Button>
                        </div>
                        {/* Dislay data */}
                            <ListOthersCharges data={otherscharges} isLoading={isLoading} />
                        {/* The create Modal */}
                        {/*This is for staffs and utres employers*/}
                        <CreateModalCharge
                            isOpen={modalIsOpenCharge}
                            onClose={closeModalCharge}
                            onCreate={(newRecord) => setOthersCharges([...otherscharges, newRecord])}
                            updateData={(newRecord) =>  setOthersCharges([...otherscharges, newRecord])}
                            apiUrl="/presms/otherscharges/"
                            initialFormData={{
                                payement_mensuel: '',
                                payement_nature: '',
                                observation: '',
                                payement_type: '',
                                payement_amount: '',
                                charge_name: '',
                                academic_year: '',
                            }}
                        />
                    </Tab>
                    </Tabs>
                </div>
            </div>
        </div> 
        <Footer />
    </>
    );
}

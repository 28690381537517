import React, { useState, useContext } from "react";
import { Table, Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { DataContext } from "../Context/DataProvider.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import GenericConfirmationModal from "../Utils/GenericConfirmationModal.js";
import axios from 'axios';

export default function ListPatrimoine({ data, setData }) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { academicYears, categories, userData } = useContext(DataContext);

  const handleDelete = (item) => {
    setSelectedItemId(item.id);
    setModalConfig({
      title: "Confirmer la suppression",
      body: `Êtes-vous sûr de vouloir supprimer l'élément ${item.nom}?`,
      onConfirm: () => handleConfirmDelete(item.id)
    });
    setIsConfirmationModalOpen(true);
  };

  const handleUpdate = (item) => {
    console.log("Updating item ID (before state update):", item.id); // Debugging log
    setSelectedItemId(item.id);
    setModalConfig({
      title: "Modifier l'élément",
      body: "Veuillez modifier les détails de l'élément.",
      initialData: item,
      onConfirm: (updatedItem) => handleConfirmUpdate(item.id, updatedItem) // Pass item.id here
    });
    console.log("Selected item ID for update (after state update):", item.id); // Debugging log
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmDelete = async (id) => {
    try {
      await axios.delete(`https://hmdgn.com/presms/patrimoine/${id}/`);
      setData((prevData) => prevData.filter((item) => item.id !== id));
      setIsConfirmationModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'élément:", error);
      // Handle error appropriately
    }
  };

  const handleConfirmUpdate = async (id, updatedItem) => { // Accept id as parameter
    console.log("Updating item ID:", id); // Debugging log
    setIsLoading(true);
    try {
      const response = await axios.put(`https://hmdgn.com/presms/patrimoine/${id}/`, updatedItem, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Update response:', response.data);
      setData((prevData) => prevData.map((item) => (item.id === id ? response.data : item))); // Use id here
      setIsConfirmationModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'élément:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="my-4">
      <Row xs={1} md={2} lg={3} xl={4} className="g-4">
        {Array.isArray(data) && data.length > 0 ? (
          data.map((item) => (
            <Col key={item.id} className="mb-3">
              <Card className="h-100 shadow">
                <Card.Header>
                  <FontAwesomeIcon icon={faHandHoldingDollar} size="5x" className="card-img-top" />
                </Card.Header>
                <Card.Body>
                  <Card.Title>Nom: {item.nom}</Card.Title>
                  <Card.Text>Description: {item.description}</Card.Text>
                  <Card.Text>Quantité: {item.quantity}</Card.Text>
                  <Card.Text>Valeur: {item.valeur} GNF</Card.Text>
                  <Card.Text>Date d'ajout: {new Date(item.created).toLocaleDateString()}</Card.Text>
                </Card.Body>
                  <Card.Footer className="d-flex justify-content-between">
                    <Button variant="danger" onClick={() => handleDelete(item)}>Supprimer</Button>
                    <Button variant="info" onClick={() => handleUpdate(item)}>Modifier</Button>
                  </Card.Footer>
                
              </Card>
            </Col>
          ))
        ) : (
          <p>En cours de telechargement. Oops...😕</p>
        )}
      </Row>
      <GenericConfirmationModal
        show={isConfirmationModalOpen}
        onHide={() => setIsConfirmationModalOpen(false)}
        onConfirm={modalConfig.onConfirm}
        title={modalConfig.title}
        body={modalConfig.body}
        initialData={modalConfig.initialData}
        isLoading={isLoading}
      />
    </Container>
  );
}
